import { toast } from "react-toastify";
import HttpStatus from "http-status-codes";

import store from "../redux/store";
import * as tokenService from "../services/token";
import { setAuthStateEmptyAction } from "../redux/auth/action";

import { AUTH_TOKEN } from "../constants/storage";
import RoutingList from "../constants/RoutingList";
// import config from "../config";

/**
 * Build authorization header
 *
 * @param {string} authToken
 * @returns {string}
 */
function getAuthorizationHeader(authToken) {
  return `${authToken}`;
}

/**
 * Interceptor to add Access Token header for all requests.
 *
 * @param {object} request
 * @returns {object}
 */
export function authorizationInterceptor(request) {
  const authToken = tokenService.getAuthToken();

  request.headers[AUTH_TOKEN] = getAuthorizationHeader(authToken);
  request.headers['Cache-Control'] = getAuthorizationHeader('no-cache');
  // request.headers["authentication_token"] = getAuthorizationHeader(authToken);
  // request.headers["auth-token"] = getAuthorizationHeader(authToken);

  return request;
}

/**
 * Interceptor to refresh Authorization header.
 *
 * @param {object} error
 * @returns {object}
 */
export async function unauthorizedResponseHandlerInterceptor(error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const status = error.response.status;

  if (status === HttpStatus.UNAUTHORIZED) {
    const { dispatch } = store;
    dispatch(setAuthStateEmptyAction());
    tokenService.clear();
    toast.error("You need to login before continuing")
    return redirectToLogin();
  }

  return Promise.reject(error);
}

/**
 * Redirects to the login page.
 *
 * @param {*} error
 */
export function redirectToLogin() {
  const loginUrl = `${window.location.origin}${RoutingList.login.index}`;
  window.location.href = loginUrl;
}
