import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useLayoutEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import HttpStatus from "http-status-codes";

import authService from "./../../../services/auth";
import * as tokenService from "./../../../services/token";
import { setAuthStateEmptyAction, setUserProfileAction } from "../../../redux/auth/action";

import LoginAuthCheck from "./LoginAuthCheck";

import Logo from "../../../assets/images/logo.png";
import RoutingList from "../../../constants/RoutingList";
import { setLoadingAction } from "../../../redux/utils/actions";
import { loginInitialValue, loginValidationSchema } from "./loginUtils";
import { toast } from "react-toastify";
import { authUserForLogin } from "../../../common/authChecker";
import logServices from "../../../services/logger";
import Footer from "../../../components/Footer/Footer";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth.user);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  // const currentYear = new Date().getFullYear();

  const submitHandler = async (values) => {
    try {
      dispatch(setLoadingAction(true));
      const res = await authService.login({
        user: {
          username: values.username,
          password: values.password,
        },
      });

      dispatch(setLoadingAction(false));

      const checkAuth = authUserForLogin(res?.data?.user);
      tokenService.setAuthToken(res?.data?.user?.authentication_token);
      if (checkAuth.res !== 1) {
        await logServices.logger({
          chrome_log: {
            user_id: res?.data?.user?.id,
            // asset_id: 451,
            status: "LOGIN_ERROR",
            date_time: new Date().toLocaleString().replaceAll("/", "-"),
            data: { message: checkAuth.message, user: res?.data?.user },
          },
        });
        return toast.error(checkAuth.message);
      } else if (checkAuth.res === 1) {
        // await logServices.logger({
        //   chrome_log: {
        //     user_id: res?.data?.user?.id,
        //     // asset_id: 451,
        //     status: "FRESH_LOGIN",
        //     date_time: new Date().toLocaleString().replaceAll("/", "-"),
        //     data: {
        //       message: "You have successfully logged in.",
        //       user: res?.data?.user,
        //     },
        //   },
        // });
      }

      dispatch(setUserProfileAction(res?.data?.user));
      if (res?.data?.user.reboot === false) {
        setIsLoginSuccess(true);
      }
      toast.success("Successfully signed in");
      // navigate(RoutingList.index);
    } catch (error) {
      dispatch(setLoadingAction(false));
      if(error.response.status === HttpStatus.UNAUTHORIZED) {
        dispatch(setAuthStateEmptyAction());
      }
      console.error(error)
      toast.error(error?.response?.data);
    }
  };
  
  useLayoutEffect(() => {
    if (isAuth) {
      navigate(RoutingList.index);
    }
  }, [isAuth, navigate]);

  return (
    <>
      <section className="login-box">
        <div className="container">
          <div className="login-wrap">
            <div className="login-logo">
              <a href="#">
                <img src={Logo} alt="Logo" />
              </a>
            </div>
            <div className="login-form">
              <h2 className="">Login</h2>
              <Formik
                enableReinitialize={true}
                initialValues={loginInitialValue}
                validationSchema={loginValidationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                  submitHandler(values);
                }}
              >
                <Form>
                  <div className="form-group">
                    <label className="form-label">
                      SYID <i className="icon-info"></i>
                    </label>
                    <Field
                      type="text"
                      name="username"
                      className="form-input"
                      placeholder="SYID"
                    />
                    <ErrorMessage
                      name="username"
                      render={(msg) => <span className="error">{msg}</span>}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">PASSWORD</label>
                    <Field
                      type="password"
                      name="password"
                      className="form-input"
                      placeholder="Password"
                    />
                    <ErrorMessage
                      name="password"
                      render={(msg) => <span className="error">{msg}</span>}
                    />
                  </div>
                  <div className="form-controls">
                    <div className="form-check custom-checkbox">
                      <div className="label-text"></div>
                    </div>
                    <div>
                      <a
                        className="forgotpassword"
                        target="_blank"
                        href="https://www.shivyogportal.com/users/forgot_password"
                        rel="noreferrer"
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>
                  <button className="btn-red" type="submit">
                    LOGIN
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
          {isLoginSuccess && <LoginAuthCheck />}
          <div className="copy-right">
            {/* <p>&copy; {currentYear} ShivYog Foundation</p> */}
            {" "}
          </div>
        </div>
        <div className="white-text">
          <Footer />
        </div>
      </section>
    </>
  );
};

export default Login;
