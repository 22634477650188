import http from "../utils/http";

import config from "../config";

import * as tokenService from "./token";

/**
 * Log out of the system.
 *
 * @param {string} refreshToken
 */
async function logout(body = {}) {
  tokenService.clear();

  // const url = `${config.baseUrl}${config.endPoints.auth.logout}`;

  // return http.post(url, { body: { ...body, from: window.location.href } });
}

/**
 * Login of the system.
 *
 * @param {object} body
 */
async function login(body) {
  tokenService.clear();
  const url = `${config.apiUrl}${config.endPoints.auth.login}`;
  return http.post(url, body);
}

async function get_user() {
  const url = `${config.apiUrl}${config.endPoints.auth.get_user}?time=${new Date().getTime()}`;
  
  // Add Cache-Control headers to the request
  return http.get(url, {
    headers: {
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expires": "0"
    }
  });
}

const authService = {
  logout,
  login,
  get_user,
};

export default authService;
