import React from "react";
import config from "../../config";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="container">
        <div className="footer-wrap">
          <p className="footer-credits">
            <span>&copy; {currentYear} ShivYog Foundation</span>
            <span>
              <a
                href="https://www.shivyogportal.com/privacy_policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              <span style={{ margin: "0 15px" }}>|</span>
              <a
                href="https://www.shivyogportal.com/contact_us"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
              
              <span style={{ margin: "0 15px" }}>|</span>
              <a href="#">Version {config.app_version}</a>
            </span>
          </p>
          <p className="trouble-shoot">
            <span> * Please try logging in again if you encounter any issues.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
