import http from "./utils/http";
// import { addListener, launch } from "devtools-detector";

import * as interceptors from "./common/interceptors";
// import store from "./redux/store";
// import { setDevtoolOpenAction } from "./redux/utils/actions";

/**
 * Initialize interceptors for the application.
 */
function initInterceptors() {
  http.interceptors.request.use(interceptors.authorizationInterceptor);
  http.interceptors.response.use(
    (response) => response,
    /**
     * This interceptor checks if the response had a 401 status code, which means
     * that the access token used for the request has expired
     */
    // interceptors.unauthorizedResponseHandlerInterceptor
  );
}

// const detectDevTools = () => {
//   if(process.env.REACT_APP_DEVTOOLS ==='open'){
//     return;
//   }

//   const view = document.createElement("div");
//   document.body.appendChild(view);
//   const { dispatch } = store;
//   // 1. add listener
//   addListener((isOpen) => dispatch(setDevtoolOpenAction(isOpen)));
//   // 2. launch detect
//   launch();
// };

export default function init() {
  // detectDevTools();
  initInterceptors();
}
